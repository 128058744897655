import { configureStore } from "@reduxjs/toolkit";
import landingPageReducer from "./landingPageSlice";
import applicationReducer from './applicationSlice';


/******************************
 * Store
 ****************************/
const store = configureStore({
  reducer: {
    landingPage: landingPageReducer,
    application: applicationReducer,
  },
});
export default store;
