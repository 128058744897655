import { createSlice } from "@reduxjs/toolkit";

export const applicationSlice = createSlice({
  name: "application",
  initialState: {
    pwa: [],
  },
  reducers: {
    addPwaInstallation: (state, action) => {
      // replace the old array with the new array
      return { ...state, pwa: action.payload };
    },
  },
});

export const { addPwaInstallation } = applicationSlice.actions;
export default applicationSlice.reducer;
