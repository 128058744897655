import { createSlice } from "@reduxjs/toolkit";

/*******************************************
 * use in the navigationmenu.js and the menuIndex.js
 * to land the menu-components
 ******************************************/
export const landingPageSlice = createSlice({
  name: "landingPage",
  initialState: {
    current: "showcase",
    openModal: false,
    introductionModal: true,
    
  },
  reducers: {
    addUpdateLandingPage: (state, action) => {
      state.current = action.payload.page;
    },
    // toggle operation, it negates the boolean value
    // use to control several modals at the same time using
    // local and global booleans
    addOpenModal: (state) => {
      return { ...state, openModal: !state.openModal };
    },
    // use in showcase.js for one time show modal
    addOpenIntroductionModal: (state) => {
      return { ...state, introductionModal: !state.introductionModal };
    },
   
  },
});
export const {
  addUpdateLandingPage,
  addOpenModal,
  addOpenIntroductionModal,
} = landingPageSlice.actions;
export default landingPageSlice.reducer;
// add arrays but does not delete the old array
// state.current.push(action.payload)
